import React, { useState, useEffect } from "react";
import { Button, Drawer, message, Space } from "antd";
import DOMPurify from "dompurify";
import styled from "styled-components";
import HtmlEditorWithPreview from "../../../components/sectionEditor/HtmlEditorWithPreview";
import { set } from "lodash";
import { MathJax, MathJaxContext } from "better-react-mathjax";

interface StatementEditorProps {
  initialData: string;
  onSave: (content: string) => Promise<void>;
}

const ButtonContainer = styled.div`
  position: absolute;
  top: 2px;
  right: 24px;
`;

const ContentDisplay = styled.div`
  padding: 24px;
  padding-top: 72px;
  margin-bottom: 24px;
  background-color: white;
  height: 100%;
  overflow: auto;
  img {
    max-width: 100%;
    max-height: 200px; /* Set your desired maximum height */
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto; /* Center the image */
  }
`;

const StatementEditor: React.FC<StatementEditorProps> = ({
  initialData,
  onSave,
}) => {
  const [decodedData, setDecodedData] = useState<string>("");
  const [editedData, setEditedData] = useState<string>("");
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    try {
      const decoded = decodeURIComponent(initialData);
      const strippedHtml = stripStylingTags(decoded);
      setDecodedData(strippedHtml);
      setEditedData(strippedHtml);
    } catch (error) {
      message.error("Failed to decode content");
      setDecodedData("Malformed data");
    }
  }, [initialData]);

  const stripStylingTags = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    doc.querySelectorAll("*").forEach((el) => {
      el.removeAttribute("style");
    });
    const tagsToRemove = ["style", "font"];
    tagsToRemove.forEach((tag) => {
      doc.querySelectorAll(tag).forEach((el) => {
        el.outerHTML = el.innerHTML;
      });
    });
    return doc.body.innerHTML;
  };

  const handleEditorChange = (content: string) => {
    setEditedData(content);
  };

  const handleSave = async () => {
    const sanitizedContent = DOMPurify.sanitize(editedData);
    setIsSaving(true);
    try {
      await onSave(sanitizedContent);
      message.success("Content saved successfully");
      setIsDrawerVisible(false);
      setDecodedData(sanitizedContent);
    } catch (error) {
      message.error("Failed to save content");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <MathJaxContext>
      <div style={{ height: "100%", position: "relative" }}>
        <ContentDisplay
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(decodedData) }}
        />
        <ButtonContainer>
          <Button
            type="primary"
            onClick={() => setIsDrawerVisible(true)}
            size="large"
          >
            Edit Content
          </Button>
        </ButtonContainer>

        <Drawer
          title={<div>Edit Content</div>}
          placement="right"
          width="80%"
          onClose={() => setIsDrawerVisible(false)}
          open={isDrawerVisible}
          extra={
            <Space>
              <Button onClick={() => setIsDrawerVisible(false)}>Cancel</Button>
              <Button type="primary" onClick={handleSave} loading={isSaving}>
                Save
              </Button>
            </Space>
          }
          style={{ padding: "24px" }}
        >
          <HtmlEditorWithPreview
            html={editedData}
            onHtmlChange={handleEditorChange}
          />
        </Drawer>
      </div>
    </MathJaxContext>
  );
};

export default StatementEditor;

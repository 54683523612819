import { apiSlice } from "../../app/api/apiSlice";
import { SchoolDetail, TeachersOverview } from "./types";

const schoolsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchools: builder.query<any, void>({
      query: () => "/schools",
    }),
    getSchoolDetails: builder.query<SchoolDetail, string>({
      query: (id) => `/schools/${id}`,
    }),
    getTeacherPerformance: builder.query<TeachersOverview, string>({
      query: (id) => `/schools/${id}/teachers`,
    }),
    impersonateSchool: builder.mutation<any, string>({
      query: (schoolId) => ({
        url: "/impersonate-school",
        method: "POST",
        body: { schoolId },
      }),
    }),
  }),
});

export const {
  useGetSchoolsQuery,
  useGetSchoolDetailsQuery,
  useGetTeacherPerformanceQuery,
  useImpersonateSchoolMutation,
} = schoolsApiSlice;

import React, { useState } from "react";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogTrigger } from "../ui/Dialog";
import { Input } from "../ui/input"; // Importing Input from shadcn
import { useNotifyUsersMutation } from "./CompetitionApiSlice";
import { toast } from "sonner";

const NotifyUsersDialog = () => {
  const [activityId, setActivityId] = useState<string>(); // State to store activityId
  const [notifyUsers, { isLoading }] = useNotifyUsersMutation();

  const handleNotifyUsers = async () => {
    if (!activityId) {
      toast.error("Please provide a valid activity ID.");
      return;
    }

    try {
      const notifiedUser = await notifyUsers(activityId).unwrap();
      if (notifiedUser.error) {
        toast.error("Failed to notify users. Please try again.");
      } else {
        toast.success("Users have been notified successfully!");
      }
    } catch (err) {
      toast.error("Failed to notify users. Please try again.");
    } finally {
      setActivityId('')
    }
  };

  return (
    <Dialog>
      <DialogTrigger>
      <div className="bg-[#a42fc6] hover:bg-[#851da3] text-white p-2 rounded-md font-medium px-3">
          Notify user
        </div>
      </DialogTrigger>
      <DialogContent>
        <div className="p-6">
          <h3 className="text-xl font-semibold">Notify Users</h3>
          <p>Please enter the activity ID to notify users about this activity:</p>

          {/* Input for activityId */}
          <Input
            type="text"
            placeholder="Enter Activity ID"
            value={activityId}
            onChange={(e) => setActivityId(e.target.value)}
            className="mt-4"
          />

          <div className="mt-4 flex justify-end space-x-3">
            <Button
              onClick={handleNotifyUsers}
              className="bg-blue-600 hover:bg-blue-500"
              disabled={isLoading || !activityId} // Button is disabled if activityId is empty
            >
              {isLoading ? "Notifying..." : "Yes, Notify"}
            </Button>
            <Button variant="secondary">Cancel</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NotifyUsersDialog;


import { Table, Space, Tag, Drawer, Typography, Input } from "antd";
import type { TableProps } from "antd";
import { Board } from "../curriculum/types";
import { School, Status } from "./types";
import styled from "styled-components";
import { useState } from "react";
import Communicator from "./components/Communicator";
import { useNavigate } from "react-router-dom";
import {
  useGetSchoolsQuery,
  useImpersonateSchoolMutation,
} from "./SchoolsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import SearchOutlined from "@ant-design/icons";
import { ADMIN_PORTAL_URL } from "../../app/constants";
export default function Schools() {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { data: schools, isLoading, error } = useGetSchoolsQuery();
  const [impersonateSchool] = useImpersonateSchoolMutation();
  const handleImpersonate = async (id: string) => {
    try {
      const response = await impersonateSchool(id).unwrap();
      const token = response?.token;
      const { userId, managedSchools, role, locales } = response?.user;
      const URL = `${ADMIN_PORTAL_URL}/?token=${token}&user=${userId}&managedSchools=${managedSchools}&role=${role}&locales=${locales}`;
      window.open(URL, "_blank");
    } catch (error) {
      console.error("Failed to impersonate school:", error);
    }
  };

  const getColumnSearchProps = (dataIndex: keyof School) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Typography.Link onClick={() => confirm()} style={{ width: 90 }}>
            Search
          </Typography.Link>
          <Typography.Link onClick={() => clearFilters()} style={{ width: 90 }}>
            Reset
          </Typography.Link>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: string, record: School) =>
      // @ts-ignore
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
  });

  const columns: TableProps<School>["columns"] = [
    {
      title: "School Name",
      dataIndex: "name",
      key: "name",
      render: (text, d) => (
        <Typography.Link onClick={() => navigate(d.id)}>{text}</Typography.Link>
      ),
      align: "left",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      align: "center",
    },
    {
      title: "Total Teachers",
      dataIndex: "totalTeachers",
      key: "totalTeachers",
      align: "center",
    },
    {
      title: "Total Students",
      dataIndex: "totalStudents",
      key: "totalStudents",
      align: "center",
    },
    {
      title: "Total Classes",
      dataIndex: "totalClasses",
      key: "totalClasses",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "status",
      align: "center",
      render: (isActive: boolean) => (
        <Tag color={isActive ? "green" : "red"}>
          {isActive ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, data: School) => (
        <Space size="middle">
          <Typography.Link onClick={() => setDrawerOpen(true)}>
            Send Notification
          </Typography.Link>
          <Typography.Link>View Report</Typography.Link>
          <Typography.Link
            onClick={() => {
              handleImpersonate(data?.id);
            }}
          >
            Impersonate
          </Typography.Link>
        </Space>
      ),
    },
  ];

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      <Container>
        <Table columns={columns} dataSource={schools} rowKey={"id"} />
      </Container>
      <Drawer
        title="Notify School"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Communicator />
      </Drawer>
    </>
  );
}

const Container = styled.div`
  padding: 20px;
`;

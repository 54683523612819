import { useRef, useEffect } from "react";

interface HtmlViewerProps {
  htmlContent: string;
  mobileView?: boolean;
}

const HtmlViewer = ({ htmlContent, mobileView = false }: HtmlViewerProps) => {
  const iframeRef = useRef(null);
  const mobileWidth = mobileView ? "375px" : "100%"; // Example mobile width
  const mobileHeight = mobileView ? "675px" : "100%"; // Example mobile height

  const cssReset = `
  /* Reset some default styles and apply box-sizing */
  html {
      box-sizing: border-box;
      font-size: 16px; /* Base font size */
  }

  *, *:before, *:after {
      box-sizing: inherit;
  }
  
  /* Ensure the body has a comfortable reading width and spacing */
  body {
      background-color: #020016;
      padding: 1.5rem 1rem 4rem 1rem;
      font-family: 'Arial', sans-serif; /* A readable, widely available font */
      font-size: 1rem; /* 16px */
      line-height: 1.5; /* Adequate line spacing for readability */
      color: #fff; /* Default text color */
      overflow-x: hidden; /* Prevent horizontal scrollbars */
      
  }
  
  /* Make images responsive */
  img {
      object-fit: contain;
      margin-bottom: 1rem;
      max-width: 100%;
      display: block;
      margin: 0 auto;
  }
  
  /* Improve readability for headings */
  h1, h2, h3, h4, h5, h6 {
      margin-top: 0;
      margin-bottom: 0.5rem;
      line-height: 1.2;
  }
  
  h1 {
      font-size: 2rem; /* Larger size for primary headings */
      text-align: center;
  }
  h2 { font-size: 1.75rem; }
  h3 { font-size: 1.5rem; }
  h4 { font-size: 1.25rem; }
  h5 { font-size: 1.125rem; }
  h6 { font-size: 1rem; }
  
  /* Style paragraphs for readability */
  p {
      margin-top: 0;
      margin-bottom: 1rem;
      word-wrap: break-word;
  }
  
  /* Ensure links are noticeable */
  a {
      color: #007bff;
      text-decoration: none;
  }
  
  a:hover {
      text-decoration: underline;
  }
  
  /* Style lists for clarity */
  ul, ol {
      margin-top: 0;
      margin-bottom: 2rem;
      padding-left: 1rem;
  }
  
  ul ul { /* Targeting nested ul elements */
    list-style-type: square; /* Solid bullets */
  }

  /* Make tables readable */
  table {
      width: 100%;
      margin-bottom: 1rem;
      border-collapse: collapse;
  }
  
  th, td {
      padding: 0.75rem;
      border: 1px solid #dee2e6;
  }
  
  th {
      background-color: #f8f9fa;
  }
  
  `;

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      // @ts-ignore
      const doc = iframe.contentDocument || iframe.contentWindow?.document;
      if (doc) {
        doc.open();
        doc.write(`
            <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0">
            <script src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/3.2.0/es5/tex-mml-chtml.js"></script>
            <script>
                window.MathJax = {
                    tex: {
                        inlineMath: [['$', '$'], ['\\(', '\\)']],
                        displayMath: [['$$', '$$'], ['\\[', '\\]']],
                        processEscapes: true
                    },
                    options: {
                        skipHtmlTags: ['script', 'noscript', 'style', 'textarea', 'pre']
                    }
                };
            </script>
            <style>${cssReset}</style>
            ${htmlContent}
          `);
        doc.close();
      }
    }
  }, [htmlContent, cssReset]);

  return (
    <iframe
      ref={iframeRef}
      width={mobileWidth}
      height={mobileHeight}
      style={{
        border: "1px solid #ccc",
        borderRadius: mobileView ? "8px" : "0",
        overflow: "hidden",
      }}
    />
  );
};

export default HtmlViewer;

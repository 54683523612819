import React, { useEffect, useState } from "react";
import { Button as AntButton, Upload, message, Spin, Drawer } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  IMCQQuestion,
  OriginalQuestionFormat,
} from "../components/dash-components/Mcq/types";

import McqEditor from "../components/dash-components/Mcq/McqEditor";
import McqViewer from "../components/dash-components/Mcq/McqViewer";
import { useUploadImageMutation } from "../app/api/apiSlice";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import { uniqueId } from "lodash";
import { parseIncomingMCQData } from "./parseIncomingMCQData";
import { useUploadMcqQuestionsMutation } from "./CompetitionApiSlice";
import { toast } from "sonner";
import { useGetQuestionsCatalogueQuery } from "../features/questions/QuestionsApiSlice";
import TopicSelector from "../components/dash-components/TopicSelector";
import { Button } from "../ui/button";

interface MCQReviewerProps {
  uploadFile?: (file: any) => Promise<string>; // Function to handle file upload
}

const MCQReviewer: React.FC<MCQReviewerProps> = ({ uploadFile }) => {
  const [questions, setQuestions] = useState<IMCQQuestion[]>([]);
  const [ImcQuestions, setImcQuestions] = useState<IMCQQuestion[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingQuestion, setEditingQuestion] = useState<
    IMCQQuestion | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [conceptsMatched, setConceptsMatched] = useState<boolean>();
  const [savedisabled, setSavedisabled] = useState<boolean>(true);
  const [preselectedClass, setPreselectedClass] = useState<string | null>(null);
  const [preselectedTopic, setPreselectedTopic] = useState<string | null>(null);
  const [preselectedSubtopic, setPreselectedSubtopic] = useState<string | null>(
    null
  );
  const [uploadAsset] = useUploadImageMutation();
  const [uploadQuestions, isSuccess] = useUploadMcqQuestionsMutation();

  const { data: classData, isSuccess: isClassDataSuccess } =
    useGetQuestionsCatalogueQuery();

  const handleFileUpload = (info: UploadChangeParam<UploadFile<any>>) => {
    const { status } = info.file;

    if (status === "done") {
      const reader = new FileReader();

      reader.onload = async (e: ProgressEvent<FileReader>) => {
        if (e.target && typeof e.target.result === "string") {
          try {
            const parsedResult = JSON.parse(
              e.target.result
            ) as OriginalQuestionFormat;

            // Use parseIncomingMCQData to transform the parsed data
            const transformedData: IMCQQuestion[] =
              parseIncomingMCQData(parsedResult);

            // Ensure each question has a unique key
            transformedData.forEach((q) => {
              q.id = uniqueId();
            });
            console.log("transfromedData", transformedData);
            // Update the state with the transformed data
            setQuestions(transformedData);
          } catch (error) {
            message.error("Failed to parse the uploaded file.");
          }
        }
      };

      if (info.file.originFileObj) {
        reader.readAsText(info.file.originFileObj);
      }
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleEdit = (question: IMCQQuestion) => {
    // console.log("wuooo", question);
    setEditingQuestion(question);
    setIsEditing(true);
  };

  const handleSave = (updatedQuestion: IMCQQuestion) => {
    // Update the questions state with the new question data
    setQuestions((prev) =>
      prev.map((q) => (q.id === updatedQuestion.id ? updatedQuestion : q))
    );
    // console.log("updated Question", updatedQuestion);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const updateFilter = (selection: any) => {
    const concepts = selection.concept || null;
    const topicId = selection.topicId || null;
    const subtopicId = selection.subtopicId || null;

    setQuestions((prevQuestions: IMCQQuestion[]) =>
      prevQuestions.map((question) => {
        const matchedTags = question.concept
          ?.map((conceptName) => {
            return concepts.find(
              (concept: any) => concept.name === conceptName
            );
          })
          .filter((tag: any) => tag !== undefined);

        let matchedIDs = matchedTags?.map((tag: any) => tag?.id);

        let quest = {
          ...question, // Keep everything else the same
          topic: topicId, // Update the topic field
          subtopic: subtopicId, // Update the subtopic field
          allConceptTags: concepts,
          conceptTags: matchedIDs,
        };
        return quest;
      })
    );
    if (subtopicId) {
      setSavedisabled(false);
    }
  };

  // Track the previous matching status

  const checkConceptMatching = (question: IMCQQuestion) => {
    const allMatched = question.concept?.every((tag) => {
      const matchedConcept = question.allConceptTags?.find(
        (concept) => concept.name === tag
      );
      return !!matchedConcept?.id;
    });

    // Only trigger toast if the matching status changes
    if (allMatched && conceptsMatched === false) {
      setSavedisabled(false); // Enable the save button
      toast.dismiss(); // Remove any previous error toast
    } else if (!allMatched && conceptsMatched === true) {
      setSavedisabled(true); // Disable the save button
      toast.error("Concepts are not matching");
    }

    // Update the matching status
    setConceptsMatched(allMatched);
  };

  // Check concept matching when questions or filters change
  useEffect(() => {
    questions.forEach(checkConceptMatching);
  }, [questions, updateFilter]);

  useEffect(() => {
    if (isClassDataSuccess && classData && questions.length > 0) {
      const firstQuestion = questions[0];
      const matchedClass = classData.find(
        (c) => c.name === firstQuestion.ClassName
      );
      if (matchedClass) {
        setPreselectedClass(matchedClass.id);
        const matchedTopic = matchedClass.topics.find(
          (t) => t.name === firstQuestion.topicName
        );

        if (matchedTopic) {
          setPreselectedTopic(matchedTopic.id);
          const matchedSubtopic = matchedTopic.subtopics.find(
            (s) => s.name === firstQuestion.subtopicName
          );
          // console.log("anything",matchedSubtopic?.id)
          if (matchedSubtopic) {
            setPreselectedSubtopic(matchedSubtopic.id);
          }
        }
      }

      // console.log(classData)
    }
  }, [isClassDataSuccess, classData, questions]);

  // console.log("preselected",preselectedClass,preselectedTopic,preselectedSubtopic)
  const onUpload = async () => {
    // Log the questions array before removing the id
    console.log("Original questions:", questions);

    // Remove the id from each question
    const removedIdQuestion = questions.map(
      ({
        id,
        allConceptTags,
        concept,
        subtopicName,
        topicName,
        ClassName,
        ...rest
      }) => rest
    );
    const uploadedQuestions = await uploadQuestions(removedIdQuestion);
    if (uploadedQuestions.error) {
      toast.error("error in uploading questions");
    } else {
      toast.success("questions uploaded successfully!!");
    }
    // Log the result to verify id removal
    console.log("Questions without id:", removedIdQuestion);

    // Perform the API upload or save operation here using removedIdQuestion
  };

  const uploadAudio = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-audio",
    }).unwrap();
    return result.url;
  };

  const uploadImage = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-image",
    }).unwrap();
    return result.url;
  };

  return (
    <div className=" p-3">
      <div className=" flex justify-between">
        {classData && questions.length > 0 && (
          <TopicSelector
            classes={classData}
            onChange={updateFilter}
            preselectedClass={preselectedClass}
            preselectedTopic={preselectedTopic}
            preselectedSubtopic={preselectedSubtopic}
          />
        )}
      </div>
      {loading ? (
        <Spin tip="Uploading and Parsing..." />
      ) : isEditing ? (
        <Drawer
          title="Edit Questions"
          open={isEditing}
          width={"90%"}
          onClose={() => setIsEditing(false)}
          styles={{ header: { paddingBottom: 32 } }}
          destroyOnClose
        >
          <McqEditor
            data={editingQuestion}
            onSave={handleSave}
            uploadAudio={uploadAudio}
            uploadImage={uploadImage}
            // onSelectionSave={onSelectionSave}
          />
        </Drawer>
      ) : (
        <div>
          {questions.length > 0 ? (
            <div className=" mt-3">
              {questions.map((question) => (
                <McqViewer
                  key={question.id}
                  question={question}
                  onEdit={() => handleEdit(question)}
                  setSavedisabled={setSavedisabled}
                />
              ))}

              <Button
                disabled={
                  savedisabled ||
                  (isSuccess && isSuccess.status === "fulfilled")
                }
                onClick={onUpload}
                className="bg-[#a42fc6] text-white ml-2 mb-4"
              >
                Save
              </Button>
            </div>
          ) : (
            <div className=" flex items-center justify-center min-h-screen">
              <Upload
                accept=".json"
                showUploadList={false}
                customRequest={({ onSuccess }) => {
                  setTimeout(() => {
                    onSuccess?.("ok" as any);
                  }, 0);
                }}
                onChange={handleFileUpload}
              >
                <AntButton
                  type="primary"
                  size="large"
                  icon={<UploadOutlined />}
                >
                  Upload File
                </AntButton>
              </Upload>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MCQReviewer;

import React from "react";
import { Card, Typography, Space, Table, Tag, Row, Col, Statistic } from "antd";
import {
  TrophyOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
  StarOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

const Home = () => {
  const teacherData = {
    name: "Shweta Tanwar",
    karmaPoints: 2672,
    questionsAdded: 20,
    questionsReviewed: 200,
    level: "Expert",
    badge: "Gold",
  };

  const leaderboard = [
    { key: "1", name: "John Doe", karmaPoints: 300 },
    { key: "2", name: "Jane Smith", karmaPoints: 280 },
    { key: "3", name: "Shweta Tanwar", karmaPoints: 200 },
    { key: "4", name: "Mike Johnson", karmaPoints: 180 },
    { key: "5", name: "Emily Brown", karmaPoints: 150 },
  ];

  const columns = [
    {
      title: "Rank",
      dataIndex: "key",
      key: "rank",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Karma Points",
      dataIndex: "karmaPoints",
      key: "karmaPoints",
    },
  ];

  return (
    <div style={{ padding: "24px", maxWidth: "1200px", margin: "0 auto" }}>
      <Title level={2}>Teacher Community Forum Dashboard</Title>

      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Card title="Teacher Profile">
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex" }}
            >
              <Title level={4}>{teacherData.name}</Title>
              <Statistic
                title="Karma Points"
                value={teacherData.karmaPoints}
                prefix={<TrophyOutlined />}
              />
              <Statistic
                title="Questions Added"
                value={teacherData.questionsAdded}
                prefix={<QuestionCircleOutlined />}
              />
              <Statistic
                title="Questions Reviewed"
                value={teacherData.questionsReviewed}
                prefix={<CheckCircleOutlined />}
              />
              <Space>
                <Text strong>Level:</Text>
                <Tag color="blue">{teacherData.level}</Tag>
              </Space>
              <Space>
                <Text strong>Badge:</Text>
                <Tag color="gold">{teacherData.badge}</Tag>
              </Space>
            </Space>
          </Card>
        </Col>

        <Col xs={24} md={12}>
          <Card title="Leaderboard">
            <Table
              dataSource={leaderboard}
              columns={columns}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>

      <Card title="Achievements" style={{ marginTop: "16px" }}>
        <Row gutter={[16, 16]}>
          <Col xs={12} sm={6}>
            <Card>
              <Statistic
                title="Karma Milestone"
                value={200}
                prefix={<TrophyOutlined />}
                suffix="points"
              />
            </Card>
          </Col>
          <Col xs={12} sm={6}>
            <Card>
              <Statistic
                title="Questions Added"
                value={20}
                prefix={<QuestionCircleOutlined />}
              />
            </Card>
          </Col>
          <Col xs={12} sm={6}>
            <Card>
              <Statistic
                title="Reviews Completed"
                value={200}
                prefix={<CheckCircleOutlined />}
              />
            </Card>
          </Col>
          <Col xs={12} sm={6}>
            <Card>
              <Statistic
                title="Current Level"
                value="Expert"
                prefix={<StarOutlined />}
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Home;

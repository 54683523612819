import React from "react";
import { Table, Tag, Typography, Button, Space, Tooltip } from "antd";
import { TrophyOutlined, BankOutlined } from "@ant-design/icons";
// Define types
type Performance = "Gold" | "Silver" | "Bronze" | "Participation";

interface Student {
  name: string;
  performance: Performance;
}

interface SchoolOlympiad {
  id: number;
  name: string;
  type: "school";
  students: Student[];
}

interface NationalOlympiad {
  id: number;
  name: string;
  type: "national";
  students: Student[];
  schoolRank: number;
  totalSchools: number;
}

type Olympiad = SchoolOlympiad | NationalOlympiad;

// Mock data
const olympiads: Olympiad[] = [
  {
    id: 1,
    name: "School Math Olympiad",
    type: "school",
    students: [
      { name: "Alice", performance: "Gold" },
      { name: "Bob", performance: "Silver" },
      { name: "Charlie", performance: "Bronze" },
    ],
  },
  {
    id: 2,
    name: "National Physics Olympiad",
    type: "national",
    students: [
      { name: "David", performance: "Silver" },
      { name: "Eve", performance: "Participation" },
    ],
    schoolRank: 5,
    totalSchools: 50,
  },
  {
    id: 3,
    name: "School Chemistry Quiz",
    type: "school",
    students: [
      { name: "Frank", performance: "Gold" },
      { name: "Grace", performance: "Silver" },
    ],
  },
  {
    id: 4,
    name: "National Biology Olympiad",
    type: "national",
    students: [
      { name: "Henry", performance: "Gold" },
      { name: "Ivy", performance: "Bronze" },
    ],
    schoolRank: 2,
    totalSchools: 100,
  },
];

const getPerformanceColor = (performance: Performance): string => {
  switch (performance) {
    case "Gold":
      return "yellow";
    case "Silver":
      return "gray";
    case "Bronze":
      return "orange";
    default:
      return "blue";
  }
};

const OlympiadPerformanceList: React.FC = () => {
  const columns = [
    {
      title: "Olympiad",
      dataIndex: "name",
      key: "name",
      render: (name: string, record: Olympiad) => (
        <Space>
          {record.type === "school" ? (
            <BankOutlined size={16} />
          ) : (
            <TrophyOutlined size={16} />
          )}
          {name}
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: "school" | "national") => (
        <Tag color={type === "school" ? "green" : "blue"}>
          {type === "school" ? "School" : "National"}
        </Tag>
      ),
    },
    {
      title: "Student Performances",
      dataIndex: "students",
      key: "students",
      render: (students: Student[]) => (
        <Space wrap>
          {students.map((student, index) => (
            <Tooltip
              key={index}
              title={`${student.name}: ${student.performance}`}
            >
              <Tag color={getPerformanceColor(student.performance)}>
                {student.name.charAt(0)}
              </Tag>
            </Tooltip>
          ))}
        </Space>
      ),
    },
    {
      title: "School Rank",
      key: "schoolRank",
      render: (record: Olympiad) =>
        record.type === "national"
          ? `${record.schoolRank} / ${record.totalSchools}`
          : "-",
    },
  ];

  const handleStartSchoolOlympiad = () => {
    console.log("Starting a new school-level olympiad");
    // Here you would typically open a modal or navigate to a new page to set up the olympiad
  };

  return (
    <div className="p-4">
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Typography.Title level={2}>Olympiad Performance List</Typography.Title>
        <Button
          type="primary"
          icon={<BankOutlined size={16} />}
          onClick={handleStartSchoolOlympiad}
        >
          Start School-Level Olympiad
        </Button>
        <Table columns={columns} dataSource={olympiads} rowKey="id" />
      </Space>
    </div>
  );
};

export default OlympiadPerformanceList;

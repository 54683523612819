import React from "react";
import CompetitionForm from "./CompetitionForm";
import ProfessionCompetitionForm from "./ProfessionCompetition";
import FoundationalForm from "./FoundationalForm";
import ShowSubjectByGrade from "./ShowSubjectByGrade";
import NotifyUsersDialog from "./NotifyUsersDialog";
import ActivityForm from "./AcademicWorksheetForm";
import AcademicWorksheetForm from "./AcademicWorksheetForm";



const AllModals = () => {
  return (
      <div className="flex flex-col items-start gap-2 ml-4 mt-4">
      <CompetitionForm  />
      <ProfessionCompetitionForm  />
      {/* <FoundationalForm /> */}
      {/* <ShowSubjectByGrade grade={10} /> */}
      <NotifyUsersDialog  />
      <AcademicWorksheetForm />
      
    </div>
  );
};

export default AllModals;









// import React, { useState } from "react";
// import CompetitionForm from "./CompetitionForm";
// import ProfessionCompetitionForm from "./ProfessionCompetition";
// import NotifyUsersDialog from "./NotifyUsersDialog";
// import AcademicWorksheetForm from "./AcademicWorksheetForm";
// import { IMtfQuestion, MatchComponentType } from "../components/dash-components/Mtf/types";
// import {MtfEditor} from "../components/dash-components/Mtf/MtfEditor";
// import MatchTheFollowingViewer from "../components/dash-components/Mtf/MtfViewer";
// import { useUploadImageMutation } from "../app/api/apiSlice";

// const dummyMatchQuestion: IMtfQuestion = {
//       en_question: [
//         {
//           type: MatchComponentType.TEXT,
//           value: "Match the following:",
//         },
//       ],
//       pairs: [
//         {
//           question: {
//             type: MatchComponentType.TEXT,
//             value: "Apple",
//           },
//           answer: {
//             type: MatchComponentType.TEXT,
//             value: "Fruit",
//           },
//         },
//         {
//           question: {
//             type: MatchComponentType.IMAGE,
//             value: "https://example.com/image1.jpg",
//           },
//           answer: {
//             type: MatchComponentType.TEXT,
//             value: "Image 1 Answer",
//           },
//         },
        
//   ],
//   id: "1",
//     };

// const AllModals = () => {
//   const [questions, setQuestions] = useState<IMtfQuestion[]>([dummyMatchQuestion]); // State for questions
//   const [editing, setEditing] = useState(false);
//   const [uploadAsset] = useUploadImageMutation();

//   const handleEdit = (question: IMtfQuestion) => {
//     handleSave(question);
//     setEditing(true);
//   };

//   const handleSave = (updatedQuestion: IMtfQuestion) => {
//     console.log("Saving updated question:", updatedQuestion); // Log the updated question
//     setQuestions((prev) =>
//       prev.map((q) => (q.id === updatedQuestion.id ? updatedQuestion : q))
//     ); // Update questions state
//     setEditing(false);
//   };

//   const handleCloseEditor = () => {
//     setEditing(false);
//   };

//   const uploadAudio = async (formData: FormData) => {
//     const result = await uploadAsset({
//       formData,
//       prefix: "mtf-audio",
//     }).unwrap();
//     return result.url;
//   };
// console.log(questions)
//   const uploadImage = async (formData: FormData) => {
//     const result: any = await uploadAsset({
//       formData,
//       prefix: "mtf-image",
//     }).unwrap();
//     return result.url;
//   };

//   return (
//     <div className="flex flex-col items-start gap-2 ml-4 mt-4">
//       <CompetitionForm />
//       <ProfessionCompetitionForm />
//       <NotifyUsersDialog />
//       <AcademicWorksheetForm />

//       {editing ? (
//         <MtfEditor
//           uploadImage={uploadImage}
//           uploadAudio={uploadAudio}
//           onSave={handleSave}
//           data={questions[0]} // Start with empty data for new questions
//         />
//       ) : (
//         questions.map((question, index) => (
//           <MatchTheFollowingViewer
//             key={index}
//             question={question}
//             onEdit={() => handleEdit(question)}
//           />
//         ))
//       )}
//     </div>
//   );
// };

// export default AllModals;

















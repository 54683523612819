import React, { useState, useRef } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { CardWrapper } from "./CardWrapper";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { Dialog, DialogContent, DialogTrigger } from "../ui/Dialog";
import { useCreateCompetitionActivityProfessionMutation } from "./CompetitionApiSlice";
import { toast } from "sonner";
import CompetitionDropdown from "./competitionDropdown";
import { Textarea } from "../ui/textarea";


const CompetitionSchema = z.object({
  profession: z.string().min(1, "Profession is required"),
  title: z.string().min(1, "Title is required"),
  stage: z.string().min(1, "Stage is required"),
  type: z.string().min(1, "Type is required"),
  description: z.string().optional(),
  startDate: z.string().min(1, "Start Date is required"),
  sections: z.array(
    z.object({
      type: z.enum(["mcq", "case-study", "assertion-reason"]),
      question: z.string().optional(),
      options: z
        .array(z.string())
        .optional() // Allow this field to be optional
        .refine(
          (options) => !options || options.length === 2 || options.length === 4,
          {
            message: "Options must be either 2 or 4.",
          }
        ),
      answer: z.string().optional(),
      explanation: z.string().optional(),
      statement: z.string().optional(),
      questions: z
        .array(
          z.object({
            question: z.string(),
            options: z
              .array(z.string())
              .refine(
                (options) =>
                  !options || options.length === 2 || options.length === 4,
                {
                  message: "Options must be either 2 or 4.",
                }
              ),
            answer: z.string(),
            explanation: z.string(),
          })
        )
        .optional(),
      assertion: z.string().optional(),
      reason: z.string().optional(),
    })
  ),
});

type CompetitionSchemaType = z.infer<typeof CompetitionSchema>;

const ProfessionCompetitionForm: React.FC = () => {
  const [competitionId, setCompetitionId] = useState<string>("");
  const [competitionJson, setCompetitionJson] =
    useState<CompetitionSchemaType | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [createCompetitionActivityProfession, { isLoading }] =
    useCreateCompetitionActivityProfessionMutation();

  const form = useForm<CompetitionSchemaType>({
    resolver: zodResolver(CompetitionSchema),
    defaultValues: {
      profession: "",
      title: "",
      stage: "",
      type: "",
      description: "",
      startDate: "",
      sections: [
        {
          type: "mcq",
          question: "",
          options: ["", "", "", ""],
          answer: "",
          explanation: "",
          statement: "",
          questions: [],
          assertion: "",
          reason: "",
        },
      ],
    },
  });

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target?.result as string);
          const validationResult = CompetitionSchema.safeParse(json);

          if (validationResult.success) {
            setCompetitionJson(validationResult.data);
            form.reset(validationResult.data); // Update sections
            toast.success("Competition JSON uploaded and validated!");
          } else {
            console.log(validationResult.error);
            toast.error("Invalid JSON format.");
          }
        } catch (err) {
          toast.error("Error parsing JSON file.");
        }
      };
      reader.readAsText(file);
    }
  };

  const onSubmit = async (competitionProfessionData: CompetitionSchemaType) => {
    try {
      const response = await createCompetitionActivityProfession({
        competitionId,
        competitionProfessionData,
      }).unwrap();

      if (response.error) {
        toast.error("Error in creating competition");
      } else {
        toast.success("Competition created successfully!");
        form.reset();
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } catch (err) {
      toast.error("Error creating competition.");
    }
  };

  const renderField = (
    label: string,
    name: keyof CompetitionSchemaType | string,
    type: "text" | "textarea" | "date" = "text",
    placeholder = ""
  ) => (
    <FormField
      key={name}
      control={form.control}
      name={name as keyof CompetitionSchemaType}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            {type === "textarea" ? (
              <Textarea
                placeholder={placeholder}
                {...field}
                value={typeof field.value === "string" ? field.value : ""}
              />
            ) : (
              <Input
                type={type}
                placeholder={placeholder}
                {...field}
                value={
                  typeof field.value === "string"
                    ? field.value
                    : field.value?.[0]?.question || ""
                }
              />
            )}
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  const renderSection = (
    section: CompetitionSchemaType["sections"][0],
    sectionIndex: number
  ) => {
    return (
      <div key={sectionIndex} className="border p-4 rounded-md">
        <h3 className="text-lg font-semibold mb-2">
          Section {sectionIndex + 1}: {section.type}
        </h3>

        {section.type === "mcq" && (
          <>
            {renderField("Question", `sections.${sectionIndex}.question`)}
            {section.options?.map((_, optionIndex) =>
              renderField(
                `Option ${optionIndex + 1}`,
                `sections.${sectionIndex}.options.${optionIndex}`
              )
            )}
            {renderField("Answer", `sections.${sectionIndex}.answer`)}
            {renderField(
              "Explanation",
              `sections.${sectionIndex}.explanation`,
              "textarea"
            )}
          </>
        )}

        {section.type === "case-study" && (
          <>
            {renderField(
              "Statement",
              `sections.${sectionIndex}.statement`,
              "textarea"
            )}
            {section.questions?.map((question, questionIndex) => (
              <div key={question.question} className="mt-4 border-t pt-4">
                <h4 className="text-md font-semibold">
                  Question {questionIndex + 1}
                </h4>
                {renderField(
                  "Question",
                  `sections.${sectionIndex}.questions.${questionIndex}.question`
                )}
                {/* {[0, 1, 2, 3].map((optionIndex) =>
                  renderField(
                    `Option ${optionIndex + 1}`,
                    `sections.${sectionIndex}.questions.${questionIndex}.options.${optionIndex}`
                  )
                )} */}
                {question.options?.map((_, optionIndex) =>
                  renderField(
                    `Option ${optionIndex + 1}`,
                    `sections.${sectionIndex}.questions.${questionIndex}.options.${optionIndex}`
                  )
                )}
                {renderField(
                  "Answer",
                  `sections.${sectionIndex}.questions.${questionIndex}.answer`
                )}
                {renderField(
                  "Explanation",
                  `sections.${sectionIndex}.questions.${questionIndex}.explanation`,
                  "textarea"
                )}
              </div>
            ))}
          </>
        )}

        {section.type === "assertion-reason" && (
          <>
            {renderField("Assertion", `sections.${sectionIndex}.assertion`)}
            {renderField("Reason", `sections.${sectionIndex}.reason`)}
            {section.options?.map((_, optionIndex) =>
              renderField(
                `Option ${optionIndex + 1}`,
                `sections.${sectionIndex}.options.${optionIndex}`
              )
            )}
            {renderField("Answer", `sections.${sectionIndex}.answer`)}
            {renderField(
              "Explanation",
              `sections.${sectionIndex}.explanation`,
              "textarea"
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="bg-[#a42fc6] hover:bg-[#851da3] text-white p-2 rounded-md font-medium px-3 cursor-pointer">
          Create profession activity
        </div>
      </DialogTrigger>
      <DialogContent>
        <CardWrapper
          title="Create Profession Competition"
          description="Upload the competition JSON file below"
          showSocial={false}
          backButtonHref="/competitions"
          backButtonLabel="Back to Competitions"
        >
          <CompetitionDropdown setCompetitionId={setCompetitionId} />
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <input
                type="file"
                accept=".json"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <Button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="w-full"
              >
                Upload Competition JSON
              </Button>

              {renderField("Profession", "profession", "text", "Profession")}
              {renderField("Title", "title", "text", "Title")}
              {renderField("Stage", "stage", "text", "Stage")}
              {renderField("Type", "type", "text", "Type")}
              {renderField(
                "Description",
                "description",
                "textarea",
                "Description"
              )}
              {renderField("Start Date", "startDate", "date")}

              {form.watch("sections").map(renderSection)}

              <Button
                type="button"
                onClick={() =>
                  form.setValue("sections", [
                    ...form.watch("sections"),
                    {
                      type: "mcq",
                      question: "",
                      options: ["", "", "", ""],
                      answer: "",
                      explanation: "",
                      statement: "",
                      questions: [],
                      assertion: "",
                      reason: "",
                    },
                  ])
                }
                className="w-full"
              >
                Add Section
              </Button>

              <Button
                type="submit"
                disabled={isLoading}
                className="w-full bg-purple-500 hover:bg-purple-600"
              >
                {isLoading ? "Creating..." : "Create"}
              </Button>
            </form>
          </Form>
        </CardWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default ProfessionCompetitionForm;

import React, { useState, useRef } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CardWrapper } from "./CardWrapper";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { Dialog, DialogContent, DialogTrigger } from "../ui/Dialog";
import { useCreateActivityMutation } from "./CompetitionApiSlice";
import { toast } from "sonner";

// Updated Zod schema for validating the activity JSON
const ActivitySchema = z.object({
  contentFromGrade: z.number().int().positive(),
  worksheetToGrade: z.array(z.number().int().positive()),
  subject: z.string().min(1, "Subject is required"),
  description: z.string().min(1, "Description is required"),
  chapter: z.string().min(1, "Chapter is required"),
  title: z.string().min(1, "Title is required"),
  author: z.string().optional(), // New field
  startDate: z.string().min(1, "Start Date is required"),
  details: z.object({
    concepts: z.array(z.string()).optional(),
    bloomLevel: z.array(z.number().int().positive()).optional(), // Updated to array
    questionCount: z.number().int().positive(),
  }),
  showInCompetition: z.boolean(),
  schoolId: z.string().optional(), // Optional field
});

const AcademicWorksheetForm = () => {
  const [activityData, setActivityData] = useState<z.infer<typeof ActivitySchema> | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [createActivity, { isLoading }] = useCreateActivityMutation();

  const form = useForm<z.infer<typeof ActivitySchema>>({
    resolver: zodResolver(ActivitySchema),
    defaultValues: {
      contentFromGrade: 1,
      worksheetToGrade: [],
      subject: "",
      description: "",
      chapter: "",
      title: "",
      author: "",
      startDate: "",
      details: {
        concepts: [],
        bloomLevel: [],
        questionCount: 1,
      },
      showInCompetition: false,
      schoolId: "",
    },
  });

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target?.result as string);
          const validationResult = ActivitySchema.safeParse(json);

          if (validationResult.success) {
            setActivityData(validationResult.data);
            form.reset(validationResult.data); // Populate form with JSON data
            toast.success("Activity JSON uploaded and validated successfully!");
          } else {
            toast.error("Invalid JSON format. Please ensure it matches the required structure.");
            resetFileInput();
          }
        } catch (err) {
          toast.error("Error parsing JSON file. Please ensure it's a valid JSON.");
          resetFileInput();
        }
      };
      reader.readAsText(file);
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setActivityData(null);
  };

  const onSubmit = async (data: z.infer<typeof ActivitySchema>) => {
    if (!data) {
      toast.error("Please upload a valid activity JSON before submitting.");
      return;
    }

    try {
      const activityResponse:any = await createActivity({ data });
      if (activityResponse.error) {
        toast.error("Failed to create Academic worksheet.");
        console.log("activity error", activityResponse.error.data.message)
        toast.error(activityResponse.error.data.message)
      } else {
        toast.success("Academic worksheet created successfully!");
        form.reset({
          contentFromGrade: 1,
          worksheetToGrade: [],
          subject: "",
          description: "",
          chapter: "",
          title: "",
          author: "",
          startDate: "",
          details: {
            concepts: [],
            bloomLevel: [],
            questionCount: 1,
          },
          showInCompetition: false,
          schoolId: "",
        });
        resetFileInput();
      }
    } catch (err) {
      toast.error("Error creating activity.");
    }
  };

  return (
    <Dialog>
      <DialogTrigger>
        <div className="bg-[#a42fc6] hover:bg-[#851da3] text-white p-2 rounded-md font-medium px-3">
          Create academic/foundational worksheet 
        </div>
      </DialogTrigger>
      <DialogContent>
        <CardWrapper
          title="Create Academic Worksheet"
          description="Upload the activity JSON file below"
          showSocial={false}
          backButtonHref="/activities"
          backButtonLabel="Back to Activities"
        >
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <input
                type="file"
                accept=".json"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <Button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="w-full"
              >
                Upload Activity JSON
              </Button>

              <FormField
                control={form.control}
                name="contentFromGrade"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Content From Grade</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        placeholder="Content From Grade"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="worksheetToGrade"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Worksheet To Grade</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Worksheet To Grade (comma separated)"
                        value={field.value.join(", ")}
                        onChange={(e) => {
                          const values = e.target.value
                            .split(",")
                            .map((val) => parseInt(val.trim(), 10))
                            .filter((val) => !isNaN(val));
                          field.onChange(values);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="subject"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Subject</FormLabel>
                    <FormControl>
                      <Input placeholder="Subject" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Input placeholder="Description" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="chapter"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Chapter</FormLabel>
                    <FormControl>
                      <Input placeholder="Chapter" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Title</FormLabel>
                    <FormControl>
                      <Input placeholder="Title" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="author"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Author</FormLabel>
                    <FormControl>
                      <Input placeholder="Author" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* <FormField
                control={form.control}
                name="startDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Start Date</FormLabel>
                    <FormControl>
                      <Input type="datetime-local" placeholder="Start Date" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              /> */}
              <FormField
                control={form.control}
                name="startDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Start Date</FormLabel>
                    <FormControl>
                      <Input type="date" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="details.concepts"
                render={({ field }) => {
                  const concepts = field?.value ?? [];
                  return (
                    <FormItem>
                      <FormLabel>Concepts (comma separated)</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Concepts"
                          value={concepts.join(", ")}
                          onChange={(e) => {
                            const values = e.target.value
                              .split(",")
                              .map(val => val.trim())
                              .filter(val => val.length > 0);
                            field?.onChange(values);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={form.control}
                name="details.bloomLevel"
                render={({ field }) => {
                  const bloomLevels = field?.value ?? [];
                  return (
                    <FormItem>
                      <FormLabel>Bloom Levels (comma separated)</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Bloom Levels"
                          value={bloomLevels.join(", ")}
                          onChange={(e) => {
                            const values = e.target.value
                              .split(",")
                              .map(val => parseInt(val.trim(), 10))
                              .filter(val => !isNaN(val));
                            field?.onChange(values);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={form.control}
                name="details.questionCount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Question Count</FormLabel>
                    <FormControl>
                      <Input type="number" placeholder="Question Count" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="schoolId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>School ID</FormLabel>
                    <FormControl>
                      <Input placeholder="School ID" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="showInCompetition"
                render={({ field }) => (
                  <FormItem className="flex gap-2 ">
                    <FormLabel className="mb-0">Show in Competition</FormLabel>
                    <FormControl>
                      <Input
                        type="checkbox"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        className="h-5 w-5 translate-y-[-9px]"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit" disabled={isLoading}>
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </form>
          </Form>
        </CardWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default AcademicWorksheetForm;

import React, { useState, useRef } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CardWrapper } from "./CardWrapper";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { Dialog, DialogContent, DialogTrigger } from "../ui/Dialog";
import { useCreateCompetitionActivityMutation } from "./CompetitionApiSlice";
import { toast } from "sonner";
import CompetitionDropdown from "./competitionDropdown";
import { Textarea } from "../ui/textarea";

// Zod schema for validating the competition JSON
const CompetitionSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().optional(),
  subtopic: z.string().min(1, "Subtopic is required"),
  type: z.string(),
  startDate: z.string(),
  questions: z.array(
    z.object({
      en_question: z.string(),
      en_options: z
        .array(z.string())
        .refine((options) => options.length === 2 || options.length === 4, {
          message: "Options must be either 2 or 4.",
        }),
      answer: z.string(),
    })
  ),
});

const CompetitionForm = () => {
  const [competitionId, setCompetitionId] = useState("");
  const [competitionData, setCompetitionData] = useState<z.infer<
    typeof CompetitionSchema
  > | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [createCompetitionActivity, { isLoading }] =
    useCreateCompetitionActivityMutation();

  const form = useForm<z.infer<typeof CompetitionSchema>>({
    resolver: zodResolver(CompetitionSchema),
    defaultValues: {
      title: "",
      description: "",
      subtopic: "",
      type: "",
      startDate: "",
      questions: [
        { en_question: "", en_options: ["", "", "", ""], answer: "" },
      ],
    },
  });

  // Handle file upload and parse the JSON
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target?.result as string);
          const validationResult = CompetitionSchema.safeParse(json);

          if (validationResult.success) {
            setCompetitionData(validationResult.data);
            // Populate the form with parsed JSON data
            form.reset(validationResult.data);
            toast.success("JSON uploaded and validated successfully!");
          } else {
            toast.error(
              "Invalid JSON format. Please ensure it matches the required structure."
            );
            resetFileInput();
          }
        } catch (err) {
          toast.error(
            "Error parsing JSON file. Please ensure it's a valid JSON."
          );
          resetFileInput();
        }
      };
      reader.readAsText(file);
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setCompetitionData(null);
  };

  const onSubmit = async (data: z.infer<typeof CompetitionSchema>) => {
    setCompetitionData(data);
    if (!data) {
      toast.error("Please upload a competition JSON file before submitting.");
      return;
    }
    console.log(data);
    console.log("compeeeee", competitionData);
    try {
      const competitionActivity = await createCompetitionActivity({
        competitionId,
        data, // Pass the updated form data here
      });

      if (competitionActivity.error) {
        toast.error("Failed to create competition.");
      } else {
        toast.success("Competition created successfully!");
      }
      form.reset({
        title: "",
        description: "",
        subtopic: "",
        type: "",
        startDate: "",
        questions: [
          { en_question: "", en_options: ["", "", "", ""], answer: "" },
        ],
      });

      resetFileInput();
    } catch (err) {
      toast.error("Error creating competition.");
    }
  };

  return (
    <Dialog>
      <DialogTrigger>
      <div className="bg-[#a42fc6] hover:bg-[#851da3] text-white p-2 rounded-md font-medium px-3">
          Create competition activity
        </div>
      </DialogTrigger>
      <DialogContent>
        <CardWrapper
          title="Create Competition"
          description="Upload competition details in JSON format"
          showSocial={false}
          backButtonHref="/competitions"
          backButtonLabel="Back to Competitions"
        >
          <CompetitionDropdown setCompetitionId={setCompetitionId} />
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <input
                type="file"
                accept=".json"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <Button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="w-full"
              >
                Upload Competition JSON
              </Button>

              {/* Form fields populated with the uploaded JSON data */}
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Title</FormLabel>
                    <FormControl>
                      <Input placeholder="Title" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea placeholder="Description" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="subtopic"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Subtopic</FormLabel>
                    <FormControl>
                      <Input placeholder="Subtopic" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Type</FormLabel>
                    <FormControl>
                      <Input placeholder="Type" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="startDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Start Date</FormLabel>
                    <FormControl>
                      <Input type="date" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Render questions and options from the JSON */}
              {/* {form.watch("questions").map((_, index) => (
                <div key={index} className="space-y-4">
                  <FormField
                    control={form.control}
                    name={`questions.${index}.en_question`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Question {index + 1}</FormLabel>
                        <FormControl>
                          <Input placeholder="Enter the question" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="grid grid-cols-2 gap-4">
                    {form.watch(`questions.${index}.en_options`).map((_, optionIndex) => (
                      <FormField
                        key={optionIndex}
                        control={form.control}
                        name={`questions.${index}.en_options.${optionIndex}`}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Option {optionIndex + 1}</FormLabel>
                            <FormControl>
                              <Input placeholder={`Option ${optionIndex + 1}`} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    ))}
                  </div>
                  <FormField
                    control={form.control}
                    name={`questions.${index}.answer`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Answer</FormLabel>
                        <FormControl>
                          <Input placeholder="Enter the correct answer" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              ))} */}
              {/* Render questions and options from the JSON */}
              {form.watch("questions").map((_, index) => (
                <div key={index} className="space-y-4">
                  <FormField
                    control={form.control}
                    name={`questions.${index}.en_question`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Question {index + 1}</FormLabel>
                        <FormControl>
                          <Input placeholder="Enter the question" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="grid grid-cols-2 gap-4">
                    {form
                      .watch(`questions.${index}.en_options`)
                      .map((_, optionIndex) => (
                        <FormField
                          key={optionIndex}
                          control={form.control}
                          name={`questions.${index}.en_options.${optionIndex}`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Option {optionIndex + 1}</FormLabel>
                              <FormControl>
                                <Input
                                  placeholder={`Option ${optionIndex + 1}`}
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      ))}
                  </div>
                  <FormField
                    control={form.control}
                    name={`questions.${index}.answer`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Answer</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Enter the correct answer"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              ))}

              <Button type="submit" disabled={isLoading }>
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </form>
          </Form>
        </CardWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default CompetitionForm;




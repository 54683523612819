import { useCallback, useEffect, useState } from "react";
import usersApi, {
  useCreatePasswordForUserMutation,
  useGetUserListQuery,
  useResetPasswordForUserMutation,
  useUpdateRolesForUserMutation,
} from "./usersApiSlice";
import { Input, Modal, Select, Space, Table, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { User, UserRole } from "./types";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorComponent from "../../components/ErrorComponent";
import debounce from "lodash/debounce";
import RoleManager from "./components/RoleManager";
import { AppDispatch, store } from "../../app/store";
import { useDispatch } from "react-redux";
import UpdatePassword from "./components/updatePasswordComp";

const { Title } = Typography;

interface ModalState {
  type: "create" | "update";
  userId: string;
  username?: string;
}

export default function Users() {
  const dispatch = useDispatch<AppDispatch>();
  const [modalState, setModalState] = useState<ModalState | undefined>();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 58,
  });
  const [filters, setFilters] = useState<
    Partial<{ role: UserRole; phone: string; email: string }>
  >({
    role: undefined,
    phone: undefined,
    email: undefined,
  });
  const [roleManagerUserId, setRoleManagerUserId] = useState<string | null>(
    null
  );

  const {
    data,
    isLoading: loading,
    isError: error,
  } = useGetUserListQuery({
    skip: (pagination.current - 1) * pagination.pageSize,
    limit: pagination.pageSize,
    ...filters,
  });

  const [createPassword] = useCreatePasswordForUserMutation();
  const [resetPassword] = useResetPasswordForUserMutation();
  const [updateRoles] = useUpdateRolesForUserMutation();

  const onRoleFilterChange = (value: UserRole) => {
    setFilters({ ...filters, role: value });
    setPagination({ ...pagination, current: 1 });
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  useEffect(() => {
    if (!data) return;
    setPagination({ ...pagination, total: data.count });
  }, [data]);

  const debouncedFilterChange = useCallback(
    debounce((key, value) => {
      setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
      setPagination((prev) => ({ ...prev, current: 1 }));
    }, 300),
    []
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => email || "NA",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role: UserRole, d: User) => {
        return (
          <Typography.Link onClick={() => setRoleManagerUserId(d.id)}>
            {role}
          </Typography.Link>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, d: any) =>
        d.username ? (
          <Typography.Link
            onClick={() =>
              setModalState({
                type: "update",
                userId: d.id,
                username: d.username,
              })
            }
          >
            Reset Password
          </Typography.Link>
        ) : (
          <Typography.Link
            onClick={() =>
              setModalState({
                type: "create",
                userId: d.id,
                username: d.username,
              })
            }
          >
            Create Password
          </Typography.Link>
        ),
    },
  ];

  const updatePasswordForUser = async (values: {
    password: string;
    username: string;
  }) => {
    try {
      if (modalState?.type === "create") {
        await createPassword({
          ...values,
          userId: modalState.userId,
        }).unwrap();
      } else if (modalState?.type === "update") {
        await resetPassword({
          ...values,
          userId: modalState.userId,
        }).unwrap();
      }
      setModalState(undefined);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateRoleForUsers = async (updatedRoles: string[]) => {
    try {
      const result = await updateRoles({
        userId: roleManagerUserId as string,
        roles: updatedRoles as UserRole[],
      }).unwrap();
      // update rtk query cache
      updateRolesInState(
        roleManagerUserId as string,
        updatedRoles as UserRole[]
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateRolesInState = (id: string, roles: UserRole[]) => {
    dispatch(
      usersApi.util.updateQueryData(
        "getUserList",
        {
          skip: (pagination.current - 1) * pagination.pageSize,
          limit: pagination.pageSize,
          ...filters,
        },
        (draft) => {
          const userIndex = draft.users.findIndex((u) => u.id === id);
          if (userIndex !== -1) {
            draft.users[userIndex] = {
              ...draft.users[userIndex],
              role: roles.join(","),
            };
          }
        }
      )
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <ErrorComponent />;
  }

  return (
    <>
      <div
        style={{ paddingLeft: 24, display: "flex", flexDirection: "column" }}
      >
        <Title level={2}>Users Management</Title>
        <Space direction="vertical" size="large">
          <Space wrap>
            <Select
              placeholder="Filter by role"
              style={{ width: 200 }}
              onChange={onRoleFilterChange}
              allowClear
            >
              <Select.Option value="admin">Admin</Select.Option>
              <Select.Option value="user">User</Select.Option>
              <Select.Option value="teacher">Teacher</Select.Option>
              <Select.Option value="student">Student</Select.Option>
              <Select.Option value="principal">Principal</Select.Option>
              <Select.Option value="reviewer">Reviewer</Select.Option>
            </Select>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search by Phone"
              onChange={(e) => debouncedFilterChange("phone", e.target.value)}
              style={{ width: 200 }}
              allowClear
            />
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search by Email"
              onChange={(e) => debouncedFilterChange("email", e.target.value)}
              style={{ width: 200 }}
              allowClear
            />
          </Space>
        </Space>
        <Table
          columns={columns}
          dataSource={data?.users}
          rowKey="id"
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ y: 380 }}
          style={{ marginTop: 24 }}
        />
      </div>
      <Modal
        title={
          modalState?.type === "create" ? "Create Password" : "Reset Password"
        }
        closable={false}
        open={modalState !== undefined}
        onCancel={() => setModalState(undefined)}
        okButtonProps={{ style: { display: "none" } }}
      >
        <UpdatePassword
          username={modalState?.username as string}
          onSubmitted={updatePasswordForUser}
        />
      </Modal>
      <Modal
        title="Role Manager"
        closable={false}
        open={modalState !== undefined}
        onCancel={() => setModalState(undefined)}
        okButtonProps={{ style: { display: "none" } }}
      >
        <UpdatePassword
          username={modalState?.username as string}
          onSubmitted={updatePasswordForUser}
        />
      </Modal>
      <Modal
        title="Role Manager"
        open={roleManagerUserId !== null}
        closable={false}
        onOk={() => setRoleManagerUserId(null)}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose={true}
      >
        <RoleManager
          roles={
            data?.users
              .find((u) => u.id === roleManagerUserId)
              ?.role.split(",") || []
          }
          availableRoles={Object.values(UserRole)}
          onUpdate={updateRoleForUsers}
        />
      </Modal>
    </>
  );
}

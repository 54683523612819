import styled from "styled-components";
import { useEffect, useRef, useState, useCallback } from "react";
import questionsApi, {
  useGetQuestionsCatalogueQuery,
  useGetQuestionsQuery,
  useUpdateQuestionMutation,
  useUpdateQuestionStatusMutation,
} from "./QuestionsApiSlice";
import { Drawer, message, Typography } from "antd";
import { Question, QuestionStatus } from "./types";
import { MathJaxContext } from "better-react-mathjax";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { LoadingOutlined } from "@ant-design/icons";
import { useThemeColors } from "../../app/theme/useThemeColors";
import TopicSelector from "../../components/dash-components/TopicSelector";
import McqViewer from "../../components/dash-components/Mcq/McqViewer";
import MCQEditor from "../../components/dash-components/Mcq/McqEditor";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import { IMCQQuestion } from "../../components/dash-components/Mcq/types";
import { skipToken } from "@reduxjs/toolkit/query";

export default function Questions() {
  const dispatch = useDispatch<AppDispatch>();
  const LIMIT = 50;
  const [page, setPage] = useState(1);
  const [questions, setQuestions] = useState<Question[]>([]);
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const [editQuestionIndex, setEditQuestionIndex] = useState(-1);
  const { data: classData, isSuccess: isClassDataSuccess } =  useGetQuestionsCatalogueQuery(); // classData takes some time
  const [saveStatus] = useUpdateQuestionStatusMutation();
  const [saveQuestion] = useUpdateQuestionMutation();
  const [uploadAsset] = useUploadImageMutation();

  const [selectedFilter, setSelectedFilter] = useState<{
    topicId: string | null;
    subtopicId: string | null;
  }>({
    topicId: null,
    subtopicId: null,
  });

  const colors = useThemeColors();
  const {
    data: questionList,
    isFetching,
    isSuccess,
    isError,
    error,
  } = useGetQuestionsQuery(
    isClassDataSuccess && selectedFilter.topicId
      ? {
        skip: (page - 1) * LIMIT,
        limit: LIMIT,
        topicId: selectedFilter.topicId || null,
        subtopicId: selectedFilter.subtopicId || null,
      }
      : skipToken
  );

  useEffect(() => {
    if (isSuccess && questionList) {
      setQuestions((prevQuestions) => {
        const newQuestions = questionList.filter(
          (newQuestion) => !prevQuestions.some((q) => q.id === newQuestion.id)
        );
        return [...prevQuestions, ...newQuestions];
      });
    }
  }, [isSuccess, questionList]);

  useEffect(() => {
    if (isError) {
      console.error("Error fetching questions:", error);
      message.error("Failed to fetch questions. Please try again.");
    }
  }, [isError, error]);

  const handleScroll = useCallback(() => {
    if (!scrollDivRef.current || isFetching) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollDivRef.current;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [isFetching]);

  useEffect(() => {
    const scrollDiv = scrollDivRef.current;
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const updateQuestion = async (data: Question) => {
    try {
      const result = await saveQuestion({
        id: data.id as string,
        data,
      }).unwrap();
      setEditQuestionIndex(-1);
      message.success("Question updated successfully");
      updateQuestionInState(data.id as string, result);
    } catch (error) {
      console.error("Failed to update question", error);
      message.error("Failed to update question");
    }
  };

  const updateQuestionInState = (id: string, data: Question) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) => (q.id === id ? data : q))
    );
    dispatch(
      questionsApi.util.updateQueryData(
        "getQuestions",
        {
          skip: (page - 1) * LIMIT,
          limit: LIMIT,
          topicId: selectedFilter.topicId || null,
          subtopicId: selectedFilter.subtopicId || null,
        },
        (draft) => {
          const index = draft.findIndex((q) => q.id === id);
          if (index !== -1) {
            draft[index] = data;
          }
        }
      )
    );
  };

  const updateStatus = async (id: string, status: QuestionStatus) => {
    try {
      const result = await saveStatus({ id, status }).unwrap();
      updateQuestionInState(id, result);
    } catch (error) {
      console.error("Failed to update question status", error);
      message.error("Failed to update question status");
    }
  };

  const uploadAudio = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-audio",
    }).unwrap();
    return result.url;
  };

  const uploadImage = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-image",
    }).unwrap();
    return result.url;
  };

  const updateFilter = (selection: any) => {
    const topicId = selection.topicId || null;
    const subtopicId = selection.subtopicId || null;
    setQuestions([]);
    setPage(1);
    setSelectedFilter({
      topicId,
      subtopicId,
    });
  };

  return (
    <>
      <Container>
        <HeaderContainer className=" mt-2.5">
          <Typography.Title level={2}>Questions</Typography.Title>
          {classData && (
            <TopicSelector classes={classData} onChange={updateFilter} />
          )}
        </HeaderContainer>
        <MathJaxContext>
          <QuestionsContainer ref={scrollDivRef}>
            {questions.map((question: any, idx: number) => (
              <McqViewer
                key={question.id}
                question={question}
                onEdit={() => setEditQuestionIndex(idx)}
              />
            ))}
            {isFetching && (
              <LoadingSpinnerContainer>
                <LoadingOutlined
                  spin
                  style={{ fontSize: 48, color: colors.primaryColor }}
                />
              </LoadingSpinnerContainer>
            )}
          </QuestionsContainer>
        </MathJaxContext>
      </Container>
      <Drawer
        open={editQuestionIndex > -1}
        onClose={() => setEditQuestionIndex(-1)}
        width={"90%"}
      >
        {editQuestionIndex > -1 && (
          <MCQEditor
            uploadAudio={uploadAudio}
            uploadImage={uploadImage}
            onSave={updateQuestion}
            data={questions[editQuestionIndex] as IMCQQuestion}
          />
        )}
      </Drawer>
    </>
  );
}

// Styled components remain the same

const Container = styled.div`
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
`;

const QuestionsContainer = styled.div`
  padding: 0.5rem 1.5rem;
  overflow-y: auto;
  height: 100%;
`;

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

import React from "react";
import {
  Card,
  Row,
  Col,
  Statistic,
  Typography,
  Avatar,
  Space,
  Flex,
  Button,
} from "antd";
import {
  UserOutlined,
  BookOutlined,
  FileOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import StatsCard from "./components/StatsCard";
import OlympiadPerformanceList from "./components/OlympiadPerformanceList";
import { useGetSchoolDetailsQuery } from "./SchoolsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";

const { Title } = Typography;

const SchoolOverview: React.FC = () => {
  const navigate = useNavigate();
  const { schoolId } = useParams();

  const { data: schoolDetails, isLoading } = useGetSchoolDetailsQuery(
    schoolId as string
  );

  if (isLoading) return <LoadingSpinner />;

  if (!schoolDetails) return null;

  return (
    <Card
      title="School detail"
      extra={
        <Space>
          <Button type="primary" size="large" title="AI Scheduler">
            AI Scheduler
          </Button>
        </Space>
      }
    >
      <div style={{ padding: "24px" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <StatsCard
              title="Total Students"
              value={schoolDetails.totalStudents}
              icon={<UserOutlined />}
              color="#1890ff"
              onViewAll={() => navigate("students")}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatsCard
              title="Teachers"
              value={schoolDetails.totalTeachers}
              icon={<TeamOutlined />}
              color="#eb2f96"
              onViewAll={() => navigate("teachers")}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatsCard
              title="Total Courses"
              value={56}
              icon={<BookOutlined />}
              color="#52c41a"
              onViewAll={() => console.log("courses")}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatsCard
              title="Assignments"
              value={789}
              icon={<FileOutlined />}
              color="#faad14"
              onViewAll={() => console.log("assignments")}
            />
          </Col>
        </Row>
      </div>

      <Title level={3} style={{ marginTop: "24px" }}>
        Grades
      </Title>
      <Row gutter={[16, 16]}>
        {schoolDetails.grades.map((grade) => (
          <Col key={grade.grade} xs={24} sm={12} md={8} lg={6}>
            <Card title={`Grade ${grade.grade}`} style={{ height: "100%" }}>
              <p>
                <strong>Sections:</strong>{" "}
                <Space>
                  {grade.sections.map((s) => (
                    <Typography.Link
                      onClick={() => navigate("grades/124")}
                      key={s.id}
                    >
                      {s.name}
                    </Typography.Link>
                  ))}
                </Space>
              </p>
              {/* <p>
                <strong>Total Students:</strong> {grade.totalStudents}
              </p>
              <p>
                <strong>Class Teacher:</strong> {grade.classTeacher}
              </p> */}
            </Card>
          </Col>
        ))}
      </Row>
      <OlympiadPerformanceList />
    </Card>
  );
};

export default SchoolOverview;

import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Statistic,
  Typography,
  Table,
  Tag,
  Input,
  Button,
  Space,
} from "antd";
import {
  UserOutlined,
  FileOutlined,
  CheckCircleOutlined,
  TrophyOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { ColumnsType, ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { useNavigate, useParams } from "react-router-dom";
import { useGetTeacherPerformanceQuery } from "./SchoolsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import { TeacherPerformance } from "./types";

const { Title } = Typography;

// Define types
// interface TeacherPerformance {
//   id: string;
//   name: string;
//   subjects: string[];
//   grades: string[];
//   totalWorksheetsSent: number;
//   overallCompletionRate: number;
//   overallAvgScore: number;
// }

// interface SchoolData {
//   totalTeachers: number;
//   avgWorksheetsSent: number;
//   avgCompletionRate: number;
//   avgScore: number;
//   teachers: TeacherPerformance[];
// }

// // Sample data (replace with actual data in a real application)
// const schoolData: SchoolData = {
//   totalTeachers: 50,
//   avgWorksheetsSent: 400,
//   avgCompletionRate: 82,
//   avgScore: 76,
//   teachers: [
//     {
//       id: "1",
//       name: "John Doe",
//       subjects: ["Mathematics", "Physics"],
//       grades: ["8", "9"],
//       totalWorksheetsSent: 450,
//       overallCompletionRate: 85,
//       overallAvgScore: 78,
//     },
//     {
//       id: "2",
//       name: "Jane Smith",
//       subjects: ["English", "Literature"],
//       grades: ["7", "8", "9"],
//       totalWorksheetsSent: 380,
//       overallCompletionRate: 88,
//       overallAvgScore: 82,
//     },
//     {
//       id: "3",
//       name: "Bob Johnson",
//       subjects: ["Biology", "Chemistry"],
//       grades: ["9", "10"],
//       totalWorksheetsSent: 420,
//       overallCompletionRate: 79,
//       overallAvgScore: 75,
//     },
//     // Add more teachers as needed
//   ],
// };

const Teachers: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const navigate = useNavigate();
  const { schoolId } = useParams();

  const { data: teacherOverview, isLoading } = useGetTeacherPerformanceQuery(
    schoolId as string
  );

  if (isLoading) return <LoadingSpinner />;

  if (!teacherOverview) return null;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: keyof TeacherPerformance
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: keyof TeacherPerformance
  ): ColumnType<TeacherPerformance> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span style={{ fontWeight: "bold" }}>{text}</span>
      ) : (
        text
      ),
  });

  const columns: ColumnsType<TeacherPerformance> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      render: (text, d) => (
        <Typography.Link onClick={() => navigate("123")}>
          {text}
        </Typography.Link>
      ),
    },
    // {
    //   title: "Subjects",
    //   dataIndex: "subjects",
    //   key: "subjects",
    //   render: (subjects: string[]) => subjects.join(", "),
    // },
    // {
    //   title: "Grades",
    //   dataIndex: "grades",
    //   key: "grades",
    //   render: (grades: string[]) => grades.join(", "),
    // },
    {
      title: "Worksheets Sent",
      dataIndex: "activities",
      key: "totalWorksheetsSent",
      sorter: (a, b) => a.activities - b.activities,
    },
    {
      title: "Completion Rate",
      dataIndex: "overallCompletionRate",
      key: "overallCompletionRate",
      // sorter: (a, b) => a.overallCompletionRate - b.overallCompletionRate,
      render: (rate: number) => `${rate}%`,
    },
    {
      title: "Avg Score",
      dataIndex: "overallAvgScore",
      key: "overallAvgScore",
      // sorter: (a, b) => a.overallAvgScore - b.overallAvgScore,
      render: (score: number) => `${score}%`,
    },
    {
      title: "Performance",
      key: "performance",
      render: (_, record) => {
        let color = "green";
        let text = "Excellent";
        // if (record.overallAvgScore < 60) {
        //   color = "red";
        //   text = "Needs Improvement";
        // } else if (record.overallAvgScore < 80) {
        //   color = "orange";
        //   text = "Good";
        // }
        return <Tag color={color}>{text}</Tag>;
      },
    },
  ];

  return (
    <Card style={{ padding: "24px" }} title={"Teachers"}>
      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Teachers"
              value={teacherOverview.totalTeachers}
              prefix={<UserOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Worksheets Sent"
              value={teacherOverview.totalWorksheetSent}
              prefix={<FileOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Avg Completion Rate"
              value={90}
              suffix="%"
              prefix={<CheckCircleOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Avg Score"
              value={72}
              suffix="%"
              prefix={<TrophyOutlined />}
            />
          </Card>
        </Col>
      </Row>

      <Title level={3}>Teacher Performance Details</Title>
      <Table
        columns={columns}
        dataSource={teacherOverview.teacherPerformance}
        rowKey="id"
      />
    </Card>
  );
};

export default Teachers;

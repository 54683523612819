import { Button, Form, Input, message } from "antd";
import { useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

interface UpdatePasswordProps {
  username?: string;
  onSubmitted: (values: { username: string; password: string }) => Promise<any>;
}

export default function UpdatePassword({
  username,
  onSubmitted,
}: UpdatePasswordProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: { username: string; password: string }) => {
    const { username, password } = values;
    if (!username || !password) {
      return;
    }
    setLoading(true);
    try {
      await onSubmitted({ username, password });
      message.success("Password updated successfully");
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error("Failed to update password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item
        name="username"
        initialValue={username}
        rules={[
          {
            required: !username,
            message: "Please input your username!",
          },
          {
            type: "string",
            min: 3,
            message: "Username must be at least 3 characters long!",
          },
        ]}
      >
        <Input
          disabled={username ? true : false}
          prefix={<UserOutlined />}
          placeholder="Username"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            type: "string",
            message: "Please enter password",
          },
        ]}
      >
        <Input prefix={<LockOutlined />} placeholder="Password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}

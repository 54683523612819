// import { Button, Card, Col, Radio, Row, Space } from "antd";
// import { OptionComponent, QuestionComponent } from "./McqEditor";
// import { IMCQQuestion } from "./types";
// import { useEffect, useState } from "react";

// export interface McqViewerProps {
//   question: IMCQQuestion;
//   onEdit?: () => void;
//   setSavedisabled?: React.Dispatch<React.SetStateAction<boolean>>;
// }

// export default function McqViewer({ question, onEdit, setSavedisabled }: McqViewerProps) {
  
//   question.concept?.map((tag) => {
//     question.allConceptTags?.find(
//       (concept) => concept.name === tag
//     )?.id;
//   });
//   return (
//     <Card
//       extra={
//         <Space>
//           {onEdit && (
//             <Button onClick={onEdit} type="primary">
//               Edit
//             </Button>
//           )}
//         </Space>
//       }
//       title="Question"
//       style={{ marginBottom: "20px" }}
//       bodyStyle={{
//         display: "flex",
//         flexDirection: "column",
//         gap: "10px",
//       }}
//     >
//       {question.en_question.map((component, idx) => (
//         <QuestionComponent {...component} key={idx} />
//       ))}
//       <Radio.Group
//         style={{ width: "100%" }}
//         value={question.en_options.find((option) => option.isCorrect)?.text}
//       >
//         <Space direction="vertical">
//           {question.en_options.map((option, idx) => {
//             return <OptionComponent option={option} key={idx} />;
//           })}
//         </Space>
//       </Radio.Group>
//       <div>
//         <div>
//           Concepts:{" "}
//           {question.concept?.map((tag) => (
//             <span
//               style={{
//                 marginRight: "5px",
//                 color: question.allConceptTags?.find(
//                   (concept) => concept.name === tag
//                 )?.id
//                   ? "green"
//                   : "red",
//               }}
//               key={tag}
//             >
//               {tag},
//             </span>
//           ))}
//         </div>
//         {/* <div>
//           Subtopics: {question.subtopic?.map((tag) => tag.name).join(", ")}
//         </div>
//         <div>Topics: {question.topic?.map((tag) => tag.name).join(", ")}</div> */}
//       </div>
//     </Card>
//   );
// }










import { Button, Card, Radio, Space, message } from "antd";
import { OptionComponent, QuestionComponent } from "./McqEditor";
import { IMCQQuestion } from "./types";
import { useEffect } from "react";

export interface McqViewerProps {
  question: IMCQQuestion;
  onEdit?: () => void;
  setSavedisabled?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function McqViewer({ question, onEdit, setSavedisabled }: McqViewerProps) {
  useEffect(() => {
    let isMatching = true;
    
    question.concept?.forEach((tag) => {
      const matchedConcept = question.allConceptTags?.find(
        (concept) => concept.name === tag
      );
      if (!matchedConcept?.id) {
        isMatching = false;
      }
    });

    if (!isMatching) {
      setSavedisabled?.(true);
      // message.error("Concepts are not matching.");
    } else {
      setSavedisabled?.(false);
    }
  }, [question, setSavedisabled]);

  return (
    <Card
      extra={
        <Space>
          {onEdit && (
            <Button onClick={onEdit} type="primary">
              Edit
            </Button>
          )}
        </Space>
      }
      title="Question"
      style={{ marginBottom: "20px" }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {question.en_question.map((component, idx) => (
        <QuestionComponent {...component} key={idx} />
      ))}
      <Radio.Group
        style={{ width: "100%" }}
        value={question.en_options.find((option) => option.isCorrect)?.text}
      >
        <Space direction="vertical">
          {question.en_options.map((option, idx) => {
            return <OptionComponent option={option} key={idx} />;
          })}
        </Space>
      </Radio.Group>
      <div>
        <div>
          Concepts:{" "}
          {question.concept?.map((tag) => (
            <span
              style={{
                marginRight: "5px",
                color: question.allConceptTags?.find(
                  (concept) => concept.name === tag
                )?.id
                  ? "green"
                  : "red",
              }}
              key={tag}
            >
              {tag},
            </span>
          ))}
        </div>
      </div>
    </Card>
  );
}

import { Button, Card, Cascader, Drawer, Form, Input, Space } from "antd";
import {
  EditOutlined,
  LeftOutlined,
  RightOutlined,
  SaveOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import HtmlPreview from "./HtmlPreview";
import HtmlEditor from "./HtmlEditor/HtmlEditor";
import QuestionListPreview from "./QuestionListPreview";
import MCQEditor from "./Mcq/McqEditor";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import { IMCQQuestion } from "./Mcq/types";

export interface CaseData {
  id?: string;
  grade: string;
  subject: string;
  chapter: string;
  conceptTags: string[];
  type: string;
  learningObjective: string;
  learningOutcome: string;
  "case-context": string;
  statement: string;
  questions: IMCQQuestion[];
  externalId?: string;
}

interface CaseReviewerProps {
  data: CaseData;
  onOk: (data: CaseData) => void;
  saveButtonText?: string;
  onPrevious?: () => void;
  onNext?: () => void;
  title?: string;
  nextDisabled?: boolean;
  previousDisabled?: boolean;
  showMetadata?: boolean;
  showQuestionsPreview?: boolean;
  saveButtonIcon?: React.ReactNode;
}

const CaseReviewer: React.FC<CaseReviewerProps> = ({
  data,
  onOk,
  onPrevious,
  onNext,
  title,
  nextDisabled,
  previousDisabled,
  saveButtonText,
  showMetadata = false,
  showQuestionsPreview = true,
  saveButtonIcon,
}: CaseReviewerProps) => {
  const [editingCase, setEditingCase] = useState<boolean>(false);
  const [editingQuestions, setEditingQuestions] = useState<boolean>(false);
  const [caseData, setCaseData] = useState<CaseData>();
  const originalCaseRef = useRef<CaseData>(data);
  const [uploadAsset] = useUploadImageMutation();

  useEffect(() => {
    if (!data) return;
    setCaseData(data);
  }, [data]);

  const handleEdit = () => {
    setEditingCase(true);
  };

  const openQuestionEditor = () => {
    setEditingQuestions(true);
  };

  const deleteQuestion = (index: number) => {
    console.log(index);
    console.log(caseData);
    // if (!caseData) return;
    // const _questions = caseData.questions;
    // delete _questions[index];
    // console.log("Setting case data");
    // setCaseData({ ...caseData, questions: _questions });
    // console.log("Case data set");
  };

  const updateCaseStatement = (statement: string) => {
    setEditingCase(false);
  };

  const onOkHandler = async () => {
    // const values = await form.validateFields();
    if (!caseData) return;
    onOk(caseData);
  };

  // MCQ Editor component props

  const updateCaseQuestion = (question: IMCQQuestion) => {
    if (!caseData) throw new Error("Case data not found");

    // Check if the question is new or existing
    const updatedQuestions = [...caseData.questions]; // does not render if caseData is not present
    // TODO: Remove this
    // const index = updatedQuestions.findIndex((q) => q.key === question.key);
    const index = -1;

    if (index === -1) {
      updatedQuestions.push(question);
    } else {
      updatedQuestions[index] = question;
    }
    setCaseData({ ...caseData, questions: updatedQuestions });
    setEditingQuestions(false);
  };

  const uploadAudio = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-audio",
    }).unwrap();
    return result.url;
  };

  const uploadImage = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-image",
    }).unwrap();
    return result.url;
  };

  if (!caseData) {
    return null;
  }

  return (
    <div>
      <Card
        title={title || "Case"}
        extra={
          <Space direction="horizontal">
            {onPrevious && (
              <Button onClick={onPrevious} disabled={previousDisabled}>
                <LeftOutlined />
              </Button>
            )}
            {onNext && (
              <Button onClick={onNext} disabled={nextDisabled}>
                <RightOutlined />
              </Button>
            )}
            <Button onClick={handleEdit} icon={<EditOutlined />}>
              Edit Content
            </Button>
            <Button onClick={openQuestionEditor} icon={<EditOutlined />}>
              {`Edit Questions (${caseData.questions.length})`}
            </Button>
            <Button
              onClick={() => onOkHandler()}
              icon={saveButtonIcon || <SaveOutlined />}
              type={"primary"}
            >
              {saveButtonText || "Save"}
            </Button>
          </Space>
        }
      >
        <div style={{ display: "flex" }}>
          <div style={{ flex: 2 }}>
            <HtmlPreview html={caseData.statement} />
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "stretch",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            {showQuestionsPreview && (
              <QuestionListPreview
                questions={caseData.questions}
                onEdit={openQuestionEditor}
                onDelete={deleteQuestion}
              />
            )}
            <Button
              size="large"
              type="dashed"
              icon={<PlusCircleOutlined />}
              onClick={openQuestionEditor}
            >
              Add question
            </Button>
          </div>
        </div>
      </Card>

      <Drawer
        title="Edit Case Content"
        open={editingCase}
        width={"90%"}
        onClose={() => setEditingCase(false)}
        destroyOnClose
        extra={
          <Space>
            <Button
              type="primary"
              size="large"
              onClick={() => setEditingCase(false)}
            >
              Ok
            </Button>
          </Space>
        }
      >
        <HtmlEditor
          html={caseData.statement}
          onHtmlChange={updateCaseStatement}
        />
      </Drawer>

      <Drawer
        title="Edit Questions"
        open={editingQuestions}
        width={"90%"}
        onClose={() => setEditingQuestions(false)}
        styles={{ header: { paddingBottom: 32 } }}
        destroyOnClose
      >
        <MCQEditor
          onSave={updateCaseQuestion}
          uploadAudio={uploadAudio}
          uploadImage={uploadImage}
        />
      </Drawer>
    </div>
  );
};

export default CaseReviewer;

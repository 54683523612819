import React, { useState, useEffect, useRef } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { result, uniqueId } from "lodash";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import CaseReviewer, {
  CaseData,
} from "../../components/dash-components/CaseReviewer";
import { useCreateCaseMutation, useUpdateCaseMutation } from "./CasesApiSlice";

const ImportStatements: React.FC = () => {
  const [cases, setCases] = useState<CaseData[]>([]);
  const [activeCaseIndex, setActiveCaseIndex] = useState<number>(0);

  const [createStatement, { data, error, isLoading }] = useCreateCaseMutation();
  const [updateCase] = useUpdateCaseMutation();

  const handleFileUpload = (info: UploadChangeParam<UploadFile<any>>) => {
    const { status } = info.file;
    if (status === "done") {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && typeof e.target.result === "string") {
          const parsedResult = JSON.parse(e.target.result);

          const cases: CaseData[] = Array.isArray(parsedResult)
            ? parsedResult
            : [parsedResult];

          cases.forEach((c) => {
            if (!c.externalId) {
              throw new Error("External ID is required");
            }
          });

          // add key to each question
          cases.forEach((c) => {
            c.questions = c.questions.map((q) => ({ ...q, key: uniqueId() }));
          });
          setCases([...cases]);
        }
      };
      if (info.file.originFileObj) {
        reader.readAsText(info.file.originFileObj);
      }
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handlePrevious = () => {
    setActiveCaseIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleNext = () => {
    setActiveCaseIndex((prev) => (prev < cases.length - 1 ? prev + 1 : prev));
  };

  const saveQuestionToServer = async (caseData: CaseData) => {
    try {
      let result: { id: string };
      if (caseData.id) {
        result = await updateCase(caseData).unwrap();
      } else {
        result = await createStatement(caseData).unwrap();
      }

      setCases((prevCases) => {
        const updatedCases = [...prevCases];
        updatedCases[activeCaseIndex] = {
          ...updatedCases[activeCaseIndex],
          ...caseData,
          id: result!.id,
        };
        return updatedCases;
      });

      message.success("Content saved successfully");
    } catch (error: FetchBaseQueryError | any) {
      if (error.status === 400) {
        message.error(
          "Invalid content. Please check the content and try again"
        );
      } else if (error.status === 409) {
        message.error("External ID conflict. This case has been saved already");
      }
    }
  };

  if (!cases || !cases.length) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Upload
          accept=".json"
          showUploadList={false}
          customRequest={({ onSuccess }) => {
            setTimeout(() => {
              onSuccess?.("ok" as any);
            }, 0);
          }}
          onChange={handleFileUpload}
        >
          <Button type="primary" size="large" icon={<UploadOutlined />}>
            Upload File
          </Button>
        </Upload>
      </div>
    );
  }

  return (
    <CaseReviewer
      title={
        cases.length > 1
          ? `Case ${activeCaseIndex + 1} of ${cases.length}`
          : "Case"
      }
      onPrevious={handlePrevious}
      onNext={handleNext}
      data={cases[activeCaseIndex]}
      onOk={saveQuestionToServer}
      showMetadata
    />
  );
};

export default ImportStatements;

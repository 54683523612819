import React from "react";
import { Card, Row, Col, Statistic, Typography, Table, Tag } from "antd";
import {
  FileOutlined,
  CheckCircleOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { useGetTeacherPerformanceQuery } from "./SchoolsApiSlice";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";

const { Title, Text } = Typography;

// Define types
interface SubjectPerformance {
  subject: string;
  grade: string;
  section: string;
  worksheetsSent: number;
  completionRate: number;
  avgScore: number;
}

interface TeacherData {
  id: string;
  name: string;
  totalWorksheetsSent: number;
  overallCompletionRate: number;
  overallAvgScore: number;
  subjectPerformance: SubjectPerformance[];
}

// Sample data (replace with actual data in a real application)
const teacherData: TeacherData = {
  id: "1",
  name: "John Doe",
  totalWorksheetsSent: 450,
  overallCompletionRate: 85,
  overallAvgScore: 78,
  subjectPerformance: [
    {
      subject: "Mathematics",
      grade: "8",
      section: "A",
      worksheetsSent: 100,
      completionRate: 90,
      avgScore: 82,
    },
    {
      subject: "Mathematics",
      grade: "8",
      section: "B",
      worksheetsSent: 100,
      completionRate: 88,
      avgScore: 80,
    },
    {
      subject: "Physics",
      grade: "9",
      section: "A",
      worksheetsSent: 80,
      completionRate: 85,
      avgScore: 75,
    },
    {
      subject: "Physics",
      grade: "9",
      section: "B",
      worksheetsSent: 80,
      completionRate: 82,
      avgScore: 73,
    },
    {
      subject: "Mathematics",
      grade: "9",
      section: "A",
      worksheetsSent: 90,
      completionRate: 80,
      avgScore: 78,
    },
  ],
};

const TeacherOverview: React.FC = () => {
  const { schoolId } = useParams();

  const { data: teacherOverview, isLoading } = useGetTeacherPerformanceQuery(
    schoolId as string
  );

  if (isLoading) return <LoadingSpinner />;
  if (!teacherOverview) return null;
  console.log("$$$$$");
  console.log(teacherOverview);
  return (
    <div style={{ padding: "24px" }}>
      <Title level={2}>Teacher Analytics: {teacherData.name}</Title>

      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total Worksheets Sent"
              value={teacherData.totalWorksheetsSent}
              prefix={<FileOutlined />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Overall Completion Rate"
              value={teacherData.overallCompletionRate}
              suffix="%"
              prefix={<CheckCircleOutlined />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Overall Average Score"
              value={teacherData.overallAvgScore}
              suffix="%"
              prefix={<TrophyOutlined />}
            />
          </Card>
        </Col>
      </Row>

      {/* <Title level={3}>Performance Breakdown</Title>
      <Row gutter={[16, 16]}>
        {teacherData.subjectPerformance.map((performance, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <Card
              title={`${performance.subject} - Grade ${performance.grade}${performance.section}`}
            >
              <Statistic
                title="Worksheets Sent"
                value={performance.worksheetsSent}
                style={{ marginBottom: "12px" }}
              />
              <Statistic
                title="Completion Rate"
                value={performance.completionRate}
                suffix="%"
                style={{ marginBottom: "12px" }}
              />
              <Statistic
                title="Average Score"
                value={performance.avgScore}
                suffix="%"
              />
            </Card>
          </Col>
        ))}
      </Row> */}

      <Title level={3} style={{ marginTop: "24px" }}>
        Detailed Performance Table
      </Title>
      <Table
        dataSource={teacherData.subjectPerformance}
        columns={[
          {
            title: "Subject",
            dataIndex: "subject",
            key: "subject",
          },
          {
            title: "Grade & Section",
            key: "gradeSection",
            render: (_, record) => `${record.grade}-${record.section}`,
          },
          {
            title: "Worksheets Sent",
            dataIndex: "worksheetsSent",
            key: "worksheetsSent",
          },
          {
            title: "Completion Rate",
            dataIndex: "completionRate",
            key: "completionRate",
            render: (rate) => `${rate}%`,
          },
          {
            title: "Average Score",
            dataIndex: "avgScore",
            key: "avgScore",
            render: (score) => `${score}%`,
          },
          {
            title: "Performance",
            key: "performance",
            render: (_, record) => {
              let color = "green";
              if (record.avgScore < 60) color = "red";
              else if (record.avgScore < 80) color = "orange";
              return (
                <Tag color={color}>
                  {record.avgScore >= 80
                    ? "Excellent"
                    : record.avgScore >= 60
                    ? "Good"
                    : "Needs Improvement"}
                </Tag>
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default TeacherOverview;
